<template>
  <vue-timepicker
    v-model="item.startAt"
    input-width="100%"
  >
  </vue-timepicker>
</template>
<script>
export default {
  props: {
    item: {
      type: Object,
      default: this
    }
  },
  methods: {
    getToDate() {
      return this.$moment(this.startAt).format('HH:mm');
    }
  }
}
</script>
